export default function ClaimPointModel() {
    return {
        id:null,
        member_id:null,
        member_no:null,
        name_on_card:null,
        transaction_code:null,
        transaction_date:null,
        tr_point_type_id:null,
        tr_point_type_name:null,
        transaction_amount:null,
        point:null,
        attachments:[],
        attachments_new:[],
        attachments_delete:[],
    };
}
