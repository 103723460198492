<template>
    <div >
        <div :class="{ 'box-read-more': isCollapsed }">
            <p v-html="htmlData"></p>
        </div>
        <a v-if="htmlData && htmlData != '' && htmlData.length > 25" href="#" @click.prevent="readMore">. . . {{ isCollapsed ? 'more':'less' }}</a>
    </div>
</template>
<script>
    export default {
        props: ["data", "index", "column"],
        data(){
            return{
                isCollapsed: true
            }
        },
        computed:{
            htmlData(){
                return this.data[this.htmlKey];
            },
            htmlKey(){
                return this.camelToSnake(this.column.substring(1));
            }
        },
        methods: {
            readMore(){
                this.isCollapsed = !this.isCollapsed
            },
            camelToSnake(string) {
                return string.replace(/[\w]([A-Z])/g, function(m) {
                    return m[0] + "_" + m[1];
                }).toLowerCase();
            }
        }
    }
</script>
