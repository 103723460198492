export default function Photo() {
    return {
        id: null,
        image: null,
        imageContentType: null,
        title: null,
        description: null,
        height: null,
        width: null,
        taken: null,
        uploaded: null,
        uuid: null
    };
}
