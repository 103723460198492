<template>
  <div class="custom-dropdown kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" :class="{'is-invalid':hasError}">
    <select
        @change="changedSelectDropdown"
        :class="{'form-control':true,'is-invalid':hasError}"
        :name="idSelectDropdown"
        v-bind:id="idSelectDropdown"
        v-model="selected"
        v-validate="validateRule"
        :value="selectedValue"
        data-size="5"
        data-live-search="true"
        :disabled="isSelectDisabled"
    >
        <!-- <option
        v-for="item in dataResponse"
        :value="item.id"
        :key="item.id"
        >{{ item.id }} - {{ renderSelect(item) }}</option> -->

        <option
        v-for="item in dataResponse"
        :value="item[valueKey]"
        :key="item[valueKey]"
        >{{ renderDisplayOption(item) }}</option>

    </select>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";

export default {
  data() {
    return {
        dataResponse: null,
        selected: null,
        disabledSelect: true
    };
  },
  inject: ['$validator'],
  props: {
    isDisabled:{
      type:Boolean
    },
    validateRule:{
      type:String
    },
    hasError:{
      type:Boolean
    },
    valueKey:{
        default: 'id',
        type: String,
        required:false
    },
    renderSelect:{
        type:Function,
        required:true
    },
    repoName:{
      type:String,
      required:true
    },
    idSelectDropdown: { type: String },
    selectedDropdown: { type: Number }
  },
  watch:{
    dataResponse(val){
      this.changedSelectDropdown()
    }
  },
  computed: {
    isSelectDisabled(){
      return this.isDisabled ? this.isDisabled : this.disabledSelect
    },
    selectRepository(){
      return RepositoryFactory.get(this.repoName);
    },
    selectedValue() {
        this.selected = this.selectedDropdown;
    },
    selectedData(){
      return (!_.isNull(this.selected))?_.find(this.dataResponse,(item)=>{
        // return item.id == this.selected;
        return item[this.valueKey] == this.selected;
      }):false;
    }
  },
  methods: {
    renderDisplayOption: function(item){
        if(item['id'] != null){
            return `${item.id}` + ' - ' + this.renderSelect(item);
        }
        return this.renderSelect(item);
    },
    getDataById: function(id){
        return this.dataResponse.find(x => x.id == id);
    },
    getSelectDropdown: function() {
        this.getData();
    },
    changedSelectDropdown: function() {
        this.$emit("update:changedValue", this.selectedData);
    },
    async getData() {
        var vx = this;
        // await UserRepository.get()
        new Promise((resolve, reject) => {
            // vx.repository.get({}, resolve, reject );
            vx.selectRepository.get({}, resolve, reject );
        })
        .then(response => {
            vx.disabledSelect = false;
            vx.dataResponse = response.data;
            $("#" + vx.idSelectDropdown).selectpicker();
            vx.$nextTick(() => {
                $("#" + vx.idSelectDropdown).selectpicker("refresh");
            });
        }).catch(error => {
        var msg = (error.message) ? error.message : error;
        this.showToastError('User : ' + msg);
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    }
  },
  mounted() {
    this.getSelectDropdown();
  }
};
</script>

