<template>
    <div>
        <base-header :base-title="$t('claimPoint.title')"></base-header>
        <div class="claim-point kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="$t('claimPoint.title')"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                            v-if="checkPermission('LoyaltyTransactionClaimResource.POST.store')">
                            &nbsp;&nbsp;
                                <base-button-modal
                                :button-text="$t('claimPoint.add')"
                                button-icon="flaticon2-add-1"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <crud-table
                    ref="crudTable"
                    :table-options="tableOptions"
                    :repository="repository"
                    :permission="permission"
                    :event="fetch"
                    @on-click-edit="onClickEdit"
                    @on-click-delete="onClickDelete"
                    @on-click-view="onClickView"
                    ></crud-table>
                </div>

                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
    </div>
</template>
<script>

    import BaseHeader from "./../../../components/_base/BaseHeader";
    import BaseTitle from "./../../../components/_base/BaseTitle";
    import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
    import CheckPermission from "./../../../services/checkPermission.service";
    import CrudTable from "./../base/crud-table/crudTable";
    import ColumnHtml from "./../base/crud-table/ColumnHtml";
    import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
    import { isNullOrUndefined } from "util";
    import FormModal from './FormModal';
    const claimPointRepository = RepositoryFactory.get("claimPointRepository");
    import { mapState, mapMutations, mapActions } from "vuex";
    const checkPermission = new CheckPermission();
    import ColumnTransactionDate from '@/components/_general/columnTableLoyalty/ColumnTransactionDate.vue'


    export default {
        components:{ BaseHeader,BaseTitle,BaseButtonModal,CrudTable,FormModal },
        methods:{
            ...mapMutations({
                setRepository: 'Crud/SET_REPOSITORY',
                refreshData: 'Crud/REFRESH_TABLE',
                showModal:'Crud/SHOW_MODAL',
                hideModal:'Crud/HIDE_MODAL',
                setModal: 'Crud/SET_MODAL',
                setupTable: 'Crud/SETUP_TABLE',
                setEditData: 'Crud/SET_EDIT_DATA',
                setViewData: 'Crud/SET_VIEW_DATA',
            }),
            checkPermission(method) {
                return checkPermission.isCanAccess(method);
            },
            fetch(){
                this.refreshData(true);
                this.hideModal();
            },

            onClickEdit(data) {
                data.attachments_new = [];
                data.attachments_delete = [];
                this.$store.commit("Crud/SET_EDIT_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");

            },
            onClickDelete(data) {
                this.$store.dispatch("Crud/deleteById", {
                    deleteRepository: claimPointRepository.delete,
                    id: data.id,
                    name: data.transaction_code
                });
            },
            onClickView(data) {

                data.attachments_new = [];
                data.attachments_delete = [];
                this.$store.commit("Crud/SET_VIEW_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");
            }

        },
        computed:{
            ...mapState("Crud", {
                modalName: state => state.modalName,
                isLoaded: state => state.isLoaded,
                table: state => state.table,
                isShowModal: state => state.isShowModal,
                showModalMode: state => state.showModalMode,
                shouldRefreshTable: state => state.shouldRefreshTable,
            })
        },
        watch:{
            shouldRefreshTable: function(newValue, oldValue) {
                if (newValue === true) {
                    this.$refs.crudTable.refreshTable();
                }
            }
        },
        data(){
            var vx = this;
            return {
                repository: claimPointRepository,
                permission: {
                    edit: this.checkPermission('LoyaltyTransactionClaimResource.PUT.update.Integer.key'),
                    delete: this.checkPermission('LoyaltyTransactionClaimResource.DELETE.destroy.Integer.key'),
                    view: this.checkPermission('LoyaltyTransactionClaimResource.GET.show.Integer.key')
                },
                tableOptions: {
                    name: "CRUD_TABLE",
                    columns: [
                        'id','name_on_card',
                        // 'member_no',
                        'transaction_code','rTransactionDate',
                        'tr_point_type_name','transaction_amount','point','actions'
                        ],
                    sortable: [
                        'id','name_on_card',
                        // 'member_no',
                        'transaction_code','rTransactionDate',
                        'tr_point_type_name','transaction_amount','point'
                    ],
                    orderBy: { column: 'id', ascending:false },
                    headings: {
                        id: "ID",
                        name_on_card:this.$t("member.name_on_card"),
                        // member_no:this.$t("member.member_no"),
                        transaction_code:this.$t("claimPoint.transactionCode"),
                        rTransactionDate:this.$t('common.transactionDate'),
                        tr_point_type_name:this.$t("claimPoint.trPointTypeName"),
                        transaction_amount:this.$t("claimPoint.transactionAmount"),
                        point:this.$t('common.point'),
                    },
                    customColumn: {
                        rTransactionDate:'transaction_date',
                        tr_point_type_name: 'tr_point_type_id'
                    },
                    columnsFilter: [
                        {key:'id',type:'number'},
                        {key:'name_on_card',type:'string'},
                        // {key:'member_no',type:'string'},
                        {key:'transaction_code',type:'string'},
                        {key:'rTransactionDate',type:'date'},
                        // {key:'tr_point_type_name',type:'string'},
                        {key:'transaction_amount',type:'number'},
                        {key:'point',type:'number'},
                    ],
                    customListFilter: {
                        tr_point_type_name : {
                            repo : 'transactionPointTypeRepository',
                            values: [],
                            renderSelect : function(val){
                                return (val.type_name) ? val.type_name : '';
                            },
                            type: 'multiselect',
                        },
                    },
                    templates: {
                        rTransactionDate: ColumnTransactionDate
                    },

                }
            }
        },
        created(){
            this.hideModal();
            this.setRepository(claimPointRepository)
            this.setModal("ClaimPointModal")
        }

    }
</script>
