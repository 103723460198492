<template>
  <p>{{ getFormatedDate }}</p>
</template>

<script>
export default {
    props: ["data", "index", "column"],
    computed:{
        getFormatedDate(){
            return moment(this.data.transaction_date).format('DD MMMM YYYY HH:MM');
        }
    },
}
</script>