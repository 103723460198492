<template>
    <div @click="focusMemberID"
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ this.claimPointModel.id ? (this.modeView ? "" : "Ubah") : "Tambah"}} {{ $t("claimPoint.title") }}</h5>
                </div>
                    <form @submit.prevent="true;">
                        <div class="modal-body">
                            <input
                                type="hidden"
                                class="form-control"
                                v-model.number="claimPointModel.id"
                            />
                            <div class="row">
                                    <div class="col-md-6 form-group" >
                                        <label for="">{{ $t('member.member_number') }}*: </label>
                                        <div class="input-group">
                                            <!-- @blur="getMemberByNumber" -->
                                            <input v-model="claimPointModel.member_no"
                                            class="form-control" :disabled="modeView"
                                            ref="memberID"
                                            title="Fokuskan kursor sebelum Scan Kode"
                                            v-validate="'required'"
                                            :readonly="isReadOnly"
                                            @keypress="getMemberInfo"
                                            :class="{'is-invalid': errors.has('member_no')}"
                                            name="member_no" :data-vv-as="$t('member.member_number')"
                                            />
                                            <div class="input-group-append">
                                                <button :disabled="isEraseDisabled" :title="$t('button.delete')" @click="resetMemberID" type="button" class="btn btn-outline-secondary"><i class="fa fa-eraser"></i></button>
                                            </div>

                                            <div class="input-group-append">
                                                <button :disabled="isEraseDisabled" :title="$t('button.delete')" @click="getMemberByNumber" type="button" class="btn btn-outline-secondary"><i class="fa fa-search"></i></button>
                                            </div>
                                            <div v-show="errors.first('member_no')" class="custom-invalid-feedback" >{{ errors.first('member_no') }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('member.member_id') }} :</label>
                                        <input v-model="claimPointModel.member_id" class="form-control"
                                        :class="{'is-invalid':errors.has('member_id')}"
                                        disabled="disabled" v-validate="'required'"
                                        name="member_id" :data-vv-as="$t('member.member_id')"/>
                                        <div v-show="errors.first('member_id')" class="custom-invalid-feedback" >{{ errors.first('member_id') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('member.name_on_card') }} :</label>
                                        <input v-model="claimPointModel.name_on_card" class="form-control"
                                        :class="{'is-invalid':errors.has('name_on_card')}"
                                        disabled="disabled"
                                        name="name_on_card" :data-vv-as="$t('member.name_on_card')"/>
                                        <div v-show="errors.first('name_on_card')" class="custom-invalid-feedback" >{{ errors.first('name_on_card') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('claimPoint.transactionCode') }}* :</label>
                                        <input v-model="claimPointModel.transaction_code" v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_code')}" ref="transactionCode"
                                        :disabled="modeView"
                                        name="transaction_code" :data-vv-as="$t('claimPoint.transactionCode')"/>
                                        <div v-show="errors.first('transaction_code')" class="custom-invalid-feedback" >{{ errors.first('transaction_code') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.transactionDate') }}* :</label>
                                        <!-- :changedValue.sync="claimPointModel.transaction_date" -->
                                        <date-picker
                                            :changedValue.sync="claimPointModel.transaction_date"
                                            @update:changedValue="onTransactionDateChangeValue"
                                            :id-date-picker="'jadwal_date_start_' + claimPointModel.id"
                                            :picker-value="claimPointModel.transaction_date"
                                            :place-holder="$t('common.transactionDate')"
                                            :is-validate="true"
                                            :isDisabled="modeView"
                                            start-date=""
                                            end-date=""
                                            ></date-picker>
                                        <div v-show="errors.first('_____name____')" class="custom-invalid-feedback" >{{ errors.first('_____name____') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('claimPoint.transactionAmount') }}* :</label>
                                        <input v-model="claimPointModel.transaction_amount" v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_amount')}"
                                        :disabled="modeView" type="number" min="0" pattern="[0-9]" step="1"
                                        @blur="calculatePoint"
                                        name="transaction_amount" :data-vv-as="$t('claimPoint.transactionAmount')"/>
                                        <div v-show="errors.first('transaction_amount')" class="custom-invalid-feedback" >{{ errors.first('transaction_amount') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('claimPoint.trPointTypeName') }}* :</label>
                                        <select-dropdown
                                            :render-select="trPointTypeRenderSelect"
                                            validate-rule="required"
                                            :isDisabled="modeView"
                                            repo-name="transactionPointTypeRepository"
                                            @update:changedValue="onTrPointTypeSelected"
                                            ref="trPointTypeSelectVue"
                                            :id-select-dropdown="'tr_point_type_id_' + claimPointModel.id"
                                            :selected-dropdown="parseInt(claimPointModel.tr_point_type_id)"
                                            ></select-dropdown>
                                        <div v-show="errors.first('tr_point_type_id')" class="custom-invalid-feedback" >{{ errors.first('tr_point_type_id') }}</div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('common.point') }}:</label>
                                        <input v-model="claimPointModel.point" v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('point')}"
                                        disabled="disabled"
                                        name="point" :data-vv-as="$t('common.point')"/>
                                        <div v-show="errors.first('point')" class="custom-invalid-feedback" >{{ errors.first('point') }}</div>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label for="">{{ $t('claimPoint.attachments') }}:</label>
                                        <div class="attachment-list">
                                            <div class="attachment-item" :class="{'danger':isNeedToRemove(file)}" v-bind:key="file.image_path" v-for="(file,index) in claimPointModel.attachments">
                                                <a target="_blank" class="attachment-link" :href="urlImage + file.image_path" >{{ $t('claimPoint.attachments') }} {{ index+ 1 }}</a>
                                                <button :title="$t('sentences.prepare_to_delete')" v-if="!isNeedToRemove(file) && !modeView" @click="toggleRemove(file)" class="btn attachment-action" type="button">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                                <button :title="$t('sentences.undo_delete_prepare')" v-if="isNeedToRemove(file) && !modeView" @click="toggleRemove(file)" class="btn attachment-action" type="button">
                                                    <i class="fa fa-undo"></i>
                                                </button>

                                            </div>
                                        </div>
                                        <div v-if="!modeView">

                                            <div class="dropzone" id="kt-dropzone-one"  :action="urlUpload" >

                                                <div class="dropzone-msg dz-message needsclick" >

                                                    <h1>
                                                        <i class="fa fa-upload"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </h1>
                                                    <h3 class="dropzone-msg-title">
                                                        Upload
                                                    </h3>
                                                    <span class="dropzone-msg-desc">
                                                        {{$t("sentences.drag_image_here_or_click_to_")}}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div v-show="errors.first('attachments')" class="custom-invalid-feedback" >{{ errors.first('attachments') }}</div>
                                    </div>

                            </div>

                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-clean btn-bold btn-upper btn-font-md"
                                @click="hideModal()"
                            >
                                {{ $t("button.close") }}
                            </button>
                            <button
                                v-if="!modeView"
                                type="button"
                                @click="submitData"
                                class="btn btn-default btn-bold btn-upper btn-font-md"
                            >
                                {{ $t("button.save") }}
                            </button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</template>
<script>
import ClaimPointModel from "./../../model/claim-point-model";
import DatePicker from "./../../../components/_general/DatePicker.vue";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import SelectDropdown from "./../../components/base/SelectDropdown";
import { isNullOrUndefined } from "util";
import Photo from "./../../model/photo-model";
import { mapState, mapMutations, mapActions } from "vuex";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import ClaimPointRepository from '../../repositories/ClaimPointRepository';
import MemberRepository from '../../repositories/MemberRepository';
import BlockUI from "./../../../components/_js/BlockUI";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";

const GalleryRepository = RepositoryFactory.get("galleryRepository");
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode('passive');
export default {
    components:{ SelectDropdown, DatePicker},

    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
            shouldRefreshTable: state => state.shouldRefreshTable,
        }),
        isAttachmentError(){
            let isValid = false;
            _.forEach(this.claimPointModel.attachments_new,(item)=>{
                if(item.status=='error' && !isValid){
                    isValid = true;
                    this.errors.add({field: 'attachments', msg: this.$t('sentences.invalid_attachments'), rule: "required"});
                }
            })
            return isValid;
        },

        isEraseDisabled(){
            return this.modeView || isNullOrUndefined(this.claimPointModel.member_no);
        },
        modeEdit(){
            return (this.dataEdit && this.dataEdit.id) || this.modeView;
        },
        modeView(){
            return this.showModalMode == 'V';
        },
    },
    provide() {
        return { $validator: this.$validator };
    },
    data(){
        return {
            isReadOnly:false,
            scan_code:null,
            claimPointModel:new ClaimPointModel(),
            listPhoto: [],
            urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
            urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt/",
        }
    },

    watch:{
        showModal(value){
            if(value){
                this.updateData();
            }
        }
    },
    methods: {
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        uploadPhoto: function(photo, file){
            var vx = this;
            const payload = JSON.stringify(photo);

            GalleryRepository.uploadPhoto(payload)
            .then(response => {
                new Promise((resolve, reject) => {
                    // vx.$emit("event", resolve);
                    resolve(response);
                }).
                then((response) => {
                    // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                    const data = {
                        uuid: file.upload.uuid,
                        image_path: response.data.alias,
                        image_id: response.data.alias,
                        // data : response.data,
                    };
                    // delete data.data.image;
                    vx.claimPointModel.attachments_new.push(data);
                    // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                    const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                    if (i > -1) {
                        vx.listPhoto.splice(i, 1);
                    }
                }).catch(error=>{
                   //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                    const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                    if (i > -1) {
                        vx.listPhoto.splice(i, 1);
                    }
                    $(file.previewElement)
                        .addClass("dz-error")
                        .find(".dz-error-message")
                        .text(
                            "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                        );
                });
            })
            .catch(error => {
                //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                if (i > -1) {
                    vx.listPhoto.splice(i, 1);
                }
                $(file.previewElement)
                    .addClass("dz-error")
                    .find(".dz-error-message")
                    .text(
                        "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                    );
            });
        },
        dropZone(){
            var vx = this;
            let dropzoneConfig = {

                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 5, // MB
                acceptedFiles: "image/jpeg,image/png,image/jpg,image/gi",
                addRemoveLinks: true,
                // autoProcessQueue: false,
                withCredentials: true,
                createImageThumbnails: true,
                url: this.urlUpload,
                headers: {  // masukan header yang dibutukan bersama token
                    Accept: "application/json, text/plain, */*",
                    Authorization: "Bearer " + localStorage.tokens,
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json;charset=UTF-8"
                },
                accept: function(file, done) {
                    // Bikin Promise, yang dilakukan adalah saat kita menerima gambar
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function(event) {
                            let photo = new Photo(); // bikin object payload image untuk di post ke API
                            var image = new Image(); // bikin object image kosong
                            image.src = event.target.result; // isi object image kosong dari file yang diupload
                            image.onload = function(i) { // saat load image masukan data image ke object payload image
                                photo.image = event.target.result.substring(
                                    event.target.result.indexOf(",") + 1
                                ); // base64 image
                                photo.title = file.name; // nama file
                                photo.description = file.name; // deskripsi biasanya pake nama juga
                                photo.imageContentType = file.type; // tipe file
                                photo.height = this.height; // tinggi foto
                                photo.width = this.width; // lebar foto
                                photo.taken = moment(file.lastModified); // waktu pengambilan
                                photo.uploaded = moment(); // waktu upload
                                photo.uuid = file.upload.uuid; // uuid untuk membedakan proses file upload
                                vx.listPhoto.push(photo); // masukan foto kedalam listphoto
                                resolve(true);
                            };
                        };
                    }).then(res => {
                        done(); // setelah proses masuk list photo selesai maka lanjutkan proses on sending
                    });
                },
                init: function() {
                    //proses pengiriman/post  gambar ke API disini kita overwrite plugin pengiriman nya pake json bukan formdata
                    // onsending dilakukan perfile berdasarkan total file yang kita pilih pas upload
                    this.on("sending", function(file, xhr, formData) {
                        // cari object photo untuk di post berdasarkan proses file yang dilakukan
                        let photo = vx.listPhoto.find(x => x.uuid == file.upload.uuid);
                        // kirim object photo
                        const x = JSON.stringify(photo);
                        xhr.send(x);
                    });
                    // kalau success upload nya maka anda akan mendapatkan response alias untuk disimpan
                    // success masuk per proses upload
                    this.on("success", function(file, response) {
                        // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                        const data = {
                            data: '',
                            dataContentType: response.imageContentType,
                            dataUrl: response.alias,
                            id: null,
                            uuid: file.upload.uuid,
                            title: response.title,
                            image_path: response.alias,
                            image_id: response.alias,
                        };
                        vx.claimPointModel.attachments_new.push(data);
                        // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                        const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                        if (i > -1) {
                            vx.listPhoto.splice(i, 1);
                        }
                        // load image/thumbnail
                        file.previewElement.querySelector("img").src = vx.urlImage + data.dataUrl;
                    });
                    this.on("error", function(file, message) {
                        //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                        const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                        if (i > -1) {
                            vx.listPhoto.splice(i, 1);
                        }
                        $(file.previewElement)
                            .addClass("dz-error")
                            .find(".dz-error-message")
                            .text(
                                "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                            );
                    });

                    this.on("removedfile", function(file) {
                        // hapus file kalau salah upload
                        // this.errors.remove('attachments');
                        const j = file.id
                        ? vx.claimPointModel.attachments_new.findIndex(
                            x => x.id == file.id
                            )
                        : vx.claimPointModel.attachments_new.findIndex(
                            x => x.uuid == file.upload.uuid
                            );
                        if (j > -1) {
                            vx.claimPointModel.attachments_new.splice(j, 1);
                        }
                    });
                }

            }

            var myDropzone = new Dropzone("#kt-dropzone-one", dropzoneConfig);
        },
        async getMemberInfo(pressedButton){
            let key = pressedButton.key;
            // this.errors.remove('code');

            this.errors.remove('member_no');
            if("Enter"==key){
                this.getMemberByNumber();
            }

        },
        getMemberByNumber(){
            // if(this.focusMemberID()){
            //    return;
            // }
            this.isReadOnly=true;
            blockUI.blockModal();
            MemberRepository.getByNumber(this.claimPointModel.member_no).then((response)=>{
                blockUI.unblockModal();
                this.claimPointModel.name_on_card = response.data.name_on_card;
                // this.claimPointModel.member_no = response.data.member_no;
                this.claimPointModel.member_id = response.data.id;
                this.$refs.transactionCode.focus();
                this.calculatePoint();
            }).catch(e=>{
                blockUI.unblockModal();
                let labelCode = this.getServerError(e);
                this.errors.add({field: 'member_no', msg: `${labelCode}`, rule: "required"});
                this.resetMemberID(false);
            });
        },
        focusMemberID(){
            if( (
                this.claimPointModel.member_no == null
                || this.claimPointModel.member_no == undefined
                || this.claimPointModel.member_no == ''
                || !this.isReadOnly
            ) && this.$refs.memberID ){
                setTimeout(()=>{
                    this.$refs.memberID.focus();
                },500);
                return true;
            }
            return false;
        },
        resetMemberID(isFocus){

            this.claimPointModel = Object.assign(this.claimPointModel,{
                member_id:null,
                name_on_card:null,
            });

            if(false!==isFocus){
                this.claimPointModel.member_no = null;
                this.isReadOnly = false;
                this.$refs.memberID.focus();
                this.errors.remove('member_no');
            }
        },
        onTrPointTypeSelected(val){
            try{
                this.claimPointModel = Object.assign(this.claimPointModel,{
                        tr_point_type_id:val.id
                });

            }catch(e){

            }
            this.calculatePoint();
            $('#tr_point_type_id_' + this.claimPointModel.id).parent().removeClass('is-invalid');
            this.errors.remove('tr_point_type_id');
        },
        onTransactionDateChangeValue(val){
            // claimPointModel.transaction_date
            this.calculatePoint();
        },
        trPointTypeRenderSelect(val){
            return val.type_name;
        },
        isNeedToRemove(item){
            return this.claimPointModel.attachments_delete.indexOf(item.id)!==-1
        },
        toggleRemove(item){
            if(this.claimPointModel.attachments_delete.indexOf(item.id)!==-1){
                this.claimPointModel.attachments_delete = _.without(this.claimPointModel.attachments_delete,item.id);
            }
            else{
                this.claimPointModel.attachments_delete.push(item.id);
            }
        },
        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },
        async update(payload,couponId) {
                var vx = this;

                payload.tiers = _.map(payload.tiers,'id').join(',');
                payload.categories = _.map(payload.categories,'id').join(',');
                await ClaimPointRepository.update(payload,couponId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Transaksi Klaim Poin berhasil diubah', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Transaksi Klaim Poin gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        calculatePoint(){
            if(
                (this.claimPointModel.member_id !== undefined && this.claimPointModel.member_id !== null)
                && (this.claimPointModel.tr_point_type_id !== null && this.claimPointModel.tr_point_type_id !== undefined)
                && (this.claimPointModel.transaction_date !== null && this.claimPointModel.transaction_date !== undefined)
                && (
                        (this.claimPointModel.transaction_amount !== null && this.claimPointModel.transaction_amount !== undefined)
                    ||  (typeof(this.claimPointModel.transaction_amount)=='number' && this.claimPointModel.transaction_amount.length>0)
                    )
                ){
                    blockUI.blockModal();
                    return ClaimPointRepository.calculatePoint({
                        member_id:this.claimPointModel.member_id,
                        tr_point_type_id:this.claimPointModel.tr_point_type_id,
                        transaction_date:this.claimPointModel.transaction_date,
                        transaction_amount:this.claimPointModel.transaction_amount,
                    }).then(response=>{
                        blockUI.unblockModal();
                        this.claimPointModel.point = response.data.point;
                    }).catch(e=>{
                        blockUI.unblockModal();
                        var vx = this;
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: vx.getServerError(
                                e,
                                "Jumlah Point tidak dapat diambil"
                            ),
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    });
            }
        },
        async create(payload) {
            var vx = this;
            await ClaimPointRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Transaksi Klaim Poin berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: vx.getServerError(
                                error,
                                "Data Transaksi Klaim Poin gagal ditambahkan."
                            ),
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    });
            },
        submitData(){
            this.errors.clear();
            this.$validator.validateAll().then(result => {
                let fields = {
                    'tr_point_type_id':this.$t('claimPoint.trPointTypeName')
                    };
                let valid = true;

                _.each(fields,(fieldAlias,keyName)=>{
                    let elTarget = $(`#${keyName}_${this.claimPointModel.id}`);
                    elTarget.parent().removeClass('is-invalid');
                    if( isNullOrUndefined(this.claimPointModel[keyName]) ){
                        this.errors.add({field: keyName, msg: `${fieldAlias} Wajib diisi`, rule: "required"});
                        elTarget.parent().addClass('is-invalid');
                        valid = false;
                    }
                });

                if(this.isAttachmentError===true){
                    valid = false;
                }

                // if(this.claimPointModel.transaction_date == null || this.claimPointModel.transaction_date == ""){
                //     $('#expired_date_'+this.couponModel.id).addClass('is-invalid');
                //     this.errors.add({field: 'expired_date', msg: this.$t('coupons.valid_until')+' Wajib diisi', rule: "required"});
                //     valid = false;
                // }

                // console.log(result);
                // console.log(valid);

                if (result && valid) {
                    var vx = this;
                    if (this.claimPointModel.id) {

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.update(vx.claimPointModel, vx.claimPointModel.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.claimPointModel.attachments = vx.claimPointModel.attachments_new;
                                vx.create(vx.claimPointModel);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }

                    return;
                }
                else{

                }
            });
        },

        updateData() {
            if (this.dataEdit) {
                this.isReadOnly = true;
                this.claimPointModel = Object.assign({}, this.dataEdit);
            }
        }
    },
    created(){

    },
    mounted() {
        this.updateData();
        if(!this.modeView){
            if(
                this.claimPointModel.member_id === null
                || this.claimPointModel.member_id === undefined

                ){
                this.focusMemberID();

            }
            this.dropZone();
        }
        $("#" + this.modalName).modal();

    }
}
</script>
